import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RCM_BLOCK_TYPE, useBlockInitAtd } from 'common/hooks/useRcm';
import { selectTopicById } from 'common/redux/commonData/topics/selectors';
import { selectTopicPageTopicId } from 'common/redux/newPages/topic/selectors';
import { getIsStarlifeTopic } from 'config/constants/common';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import {
  WidgetsColumnPropsType,
  renderWidgetsList,
} from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn';
import { WidgetsListBase } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/components/Base';
import { WidgetsType } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/typings';

const widgetsConfig = [
  WidgetsType.Hotnews,
  {
    projectId: PROJECT_IDS.News,
    index: 0,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.News,
    index: 1,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.News,
    index: 2,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  WidgetsType.Opinions,
  {
    projectId: PROJECT_IDS.News,
    index: 3,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.News,
    index: 4,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
];

const widgetsConfigStarlife = [
  {
    projectId: PROJECT_IDS.News,
    index: 0,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.News,
    index: 1,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.News,
    index: 2,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.News,
    index: 3,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.News,
    index: 4,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.News,
    index: 5,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.News,
    index: 6,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
];

export const NewsWidgetsTopic = memo(({ puids }: WidgetsColumnPropsType) => {
  const topicId = useSelector(selectTopicPageTopicId);
  const topic = useSelector(selectTopicById(topicId), shallowEqual);

  // Логика для этой задачи:
  // https://jira.rambler-co.ru/browse/NEWS-11640
  const isStarLifeTopic = getIsStarlifeTopic(topic?.alias);

  useBlockInitAtd(RCM_BLOCK_TYPE.topNewsWidgetDesktop);

  return (
    <WidgetsListBase puids={puids}>
      {renderWidgetsList(
        isStarLifeTopic ? widgetsConfigStarlife : widgetsConfig,
      )}
    </WidgetsListBase>
  );
});
